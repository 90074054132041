import './Home.css';
import Facebook from '../assets/images/icons8-facebook-96.png';
import Instagram from '../assets/images/icons8-instagram-96.png';

function Home() {
	return (
		<section id="home">
			<div className="hero">
				{/* <img src="/images/hero-image.jpg" alt="Balloon Decorations by Susan" className="hero-image" /> */}
				<div className="hero-content">
					<h1>
						Welcome to <br />
						Pop Designs By Susan!
					</h1>
					<p>We bring your celebrations to life with stunning balloon decorations.</p>
					<a href="#contact">
						<button className="cta-button">Book Now</button>
					</a>
					<div className="social">
						<a href="https://facebook.com/popdesignsbysusan" target="_blank" rel="noopener noreferrer">
							<img src={Facebook} alt="Facebook" className="social-icon" />
						</a>
						<a href="https://instagram.com/popdesignsbysusan" target="_blank" rel="noopener noreferrer">
							<img src={Instagram} alt="Instagram" className="social-icon" />
						</a>
					</div>
					<div className="width-100">
						<b>Email:</b>{' '}
						<a href="mailto:popdesignsbysusan@gmail.com" className="link-static">
							PopDesignsBySusan@gmail.com
						</a>
					</div>
				</div>
			</div>

			<div id="services" className="services">
				<h2>Our Services</h2>
				<p>At Pop Designs By Susan, we specialize in creating unique and colorful balloon decorations tailored to your special occasions. We offer a variety of decoration styles, including:</p>
				<ul>
					<li>
						<strong>Balloon Arches:</strong> Perfect for event entrances, stages, and backdrops.
					</li>
					<li>
						<strong>Balloon Columns:</strong> Add a touch of celebration to any space with customizable columns that fit the theme of your event.
					</li>
					<li>
						<strong>Table Toppers:</strong> Elegant centerpieces to make your tables shine.
					</li>
					<li>
						<strong>Custom Balloon Decor:</strong> Whether it’s numbers, letters, or special shapes, we’ll bring your vision to life with creative and personalized designs.
					</li>
				</ul>
				<p>Let us transform your party into an unforgettable experience!</p>
			</div>

			<div id="about" className="about">
				<h2>About Susan</h2>
				<p>Susan is passionate about bringing joy and beauty to events through the art of balloon decoration. With an eye for color and creativity, follow her journey on Facebook and Instagram to see the latest decorations, tips, and inspiration. She has been helping customers celebrate life’s precious moments with style. From birthdays and weddings to corporate events, Susan ensures that every decoration is crafted to perfection and tailored to fit the unique atmosphere of each occasion.</p>
			</div>
		</section>
	);
}

export default Home;
