// src/components/Header.js
import React, { useState } from 'react';
import './Header.css';

function Header() {
	const [menuOpen, setMenuOpen] = useState(false);

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	return (
		<header className="header">
			{/* <div className="logo">
				<h1>Pop Designs By Susan</h1>
			</div> */}
			<div className="menu-bar" onClick={toggleMenu}>
				<div className="bar"></div>
				<div className="bar"></div>
				<div className="bar"></div>
			</div>
			<nav className={menuOpen ? 'nav open' : 'nav'}>
				<ul>
					<li>
						<a href="#services" onClick={toggleMenu}>
							Services
						</a>
					</li>
					<li>
						<a href="#about" onClick={toggleMenu}>
							About Susan
						</a>
					</li>
					<li>
						<a href="#gallery" onClick={toggleMenu}>
							Gallery
						</a>
					</li>
					<li>
						<a href="#contact" onClick={toggleMenu}>
							Contact Susan
						</a>
					</li>
				</ul>
			</nav>
		</header>
	);
}

export default Header;
