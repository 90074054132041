import React, { useState, useEffect } from 'react';
import './Gallery.css'; // Create a CSS file for the gallery styles
import galleryImage01 from '../assets/gallery/gallery01.png';
import galleryImage02 from '../assets/gallery/gallery02.png';
import galleryImage03 from '../assets/gallery/gallery03.png';
import galleryImage04 from '../assets/gallery/gallery04.jpg';
import galleryImage05 from '../assets/gallery/gallery05.jpg';
import galleryImage06 from '../assets/gallery/gallery06.png';
import galleryImage07 from '../assets/gallery/gallery07.png';

function Gallery() {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [currentImage, setCurrentImage] = useState(null);
	const [imagesPerView, setImagesPerView] = useState(1);

	const images = [galleryImage01, galleryImage02, galleryImage03, galleryImage04, galleryImage05, galleryImage06, galleryImage07];

	useEffect(() => {
		const updateImagesPerView = () => {
			const width = window.innerWidth;
			const imagesToShow = Math.floor(width / 260) - 1;
			setImagesPerView(imagesToShow > 0 ? imagesToShow : 1);
		};

		updateImagesPerView();
		window.addEventListener('resize', updateImagesPerView);

		return () => {
			window.removeEventListener('resize', updateImagesPerView);
		};
	}, []);

	const handlePrevious = () => {
		setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - imagesPerView : prevIndex - 1));
	};

	const handleNext = () => {
		setCurrentIndex((prevIndex) => (prevIndex >= images.length - imagesPerView ? 0 : prevIndex + 1));
	};

	const openViewer = (image) => {
		setCurrentImage(image);
		setIsViewerOpen(true);
		document.body.style.overflow = 'hidden'; // Prevent scrolling when the viewer is open
	};

	const closeViewer = () => {
		setIsViewerOpen(false);
		setCurrentImage(null);
		document.body.style.overflow = 'auto'; // Restore scrolling when the viewer is closed
	};

	return (
		<section id="gallery" className="gallery">
			<h2>Gallery</h2>
			<div className="carousel">
				<button className="carousel-button left" onClick={handlePrevious}>
					&lt;
				</button>
				<div className="carousel-images">
					{images.slice(currentIndex, currentIndex + imagesPerView).map((image, index) => (
						<img key={index} src={image} alt={`Image ${currentIndex + index + 1}`} className="carousel-image" onClick={() => openViewer(image)} />
					))}
				</div>
				<button className="carousel-button right" onClick={handleNext}>
					&gt;
				</button>
			</div>
			{isViewerOpen && (
				<div className="image-viewer" onClick={closeViewer}>
					<img src={currentImage} alt="Full View" className="image" draggable="false" />
					<button className="close-button" onClick={closeViewer}>
						X
					</button>
				</div>
			)}
		</section>
	);
}

export default Gallery;
