import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Gallery from './components/Gallery';
import Contact from './components/Contact';

function App() {
	return (
		<div className="App">
			<Header />
			<Home />
			<Gallery />
			<Contact />
		</div>
	);
}

export default App;

