import React, { useState } from 'react';
import './Contact.css';

function Contact() {
	const defaultData = {
		name: '',
		email: '',
		company: '',
		address: '',
		city: '',
		state: '',
		startdate: '',
		enddate: '',
		message: '',
	};
	const [formData, setFormData] = useState(defaultData);
	const [statusMessage, setStatusMessage] = useState('');
	const [showOverlay, setShowOverlay] = useState(false);
	// const [recaptchaReady, setRecaptchaReady] = useState(false);

	// useEffect(() => {
	// 	const checkRecaptcha = () => {
	// 		if (window.grecaptcha && window.grecaptcha.enterprise) {
	// 			window.grecaptcha.enterprise.ready(() => {
	// 				setRecaptchaReady(true);
	// 			});
	// 		} else {
	// 			setTimeout(checkRecaptcha, 500);
	// 		}
	// 	};
	// 	checkRecaptcha();
	// }, []);

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const empty = (variable) => {
		if (variable == '' || !variable || variable == undefined || variable == 'undefined') {
			return true;
		}
		return false;
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		// if (!recaptchaReady) {
		// 	setStatusMessage('reCAPTCHA is not ready. Please try again shortly.');
		// 	setShowOverlay(true);
		// 	return;
		// }

		// Request reCAPTCHA token
		// window.grecaptcha.enterprise.execute('6LcQFWoqAAAAAMqtQBdEkuQp1CSvo3xejiFEotwM', { action: 'submit' }).then((token) => {
		// Append form data and reCAPTCHA token
		const data = new FormData();
		data.append('name', formData.name);
		data.append('email', formData.email);
		data.append('company', formData.company);
		data.append('address', formData.address);
		data.append('startdate', formData.startdate);
		data.append('enddate', formData.enddate);
		data.append('message', formData.message);
		data.append('city', formData.city);
		data.append('state', formData.state);
		// data.append('recaptchaToken', token);

		// Send form data to PHP page using Fetch API
		fetch('https://popdesignsbysusan.com/contact.php', {
			method: 'POST',
			body: data,
		})
			.then((response) => response.json())
			.then((data) => {
				let message = '';
				if (!empty(data.message)) {
					message = data.message;
				}
				if (data.status === 'success') {
					if (empty(message)) {
						message = 'Message sent successfully!';
					}
					setFormData(defaultData);
				} else if (data.status === 'error') {
					if (empty(message)) {
						message = 'There was an error sending your message.';
					}
				} else if (data.status === 'invalid') {
					if (empty(message)) {
						message = 'Please fill in all required fields.';
					}
				} else {
					if (empty(message)) {
						message = 'Unexpected response.';
					}
				}
				if (!empty(message)) {
					setStatusMessage(message);
					setShowOverlay(true);
				}
			})
			.catch((error) => {
				setStatusMessage('There was an error: ' + error.message);
				setShowOverlay(true);
			});

		// Hide the overlay after 2 seconds
		setTimeout(() => {
			setShowOverlay(false);
			setStatusMessage('');
		}, 2000);
		// });
	};

	return (
		<>
			<section id="contact">
				<h2>Contact Susan</h2>
				<form className="contact-form" onSubmit={handleSubmit}>
					<label htmlFor="name">* Name:</label>
					<input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
					<label htmlFor="email">* Email:</label>
					<input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
					<label htmlFor="company">Company:</label>
					<input type="text" id="company" name="company" value={formData.company} onChange={handleChange} />
					<label htmlFor="address">Address:</label>
					<input type="text" id="address" name="address" value={formData.address} onChange={handleChange} />
					<input type="text" id="city" name="city" value={formData.city} onChange={handleChange} className="input-check" />
					<input type="text" id="state" name="state" value={formData.state} onChange={handleChange} className="input-check" />
					<label htmlFor="email">Event Dates:</label>
					<div className="input-wrapper">
						<input type="date" id="startdate" name="startdate" value={formData.startdate} onChange={handleChange} className="input-date" /> - <input type="date" id="enddate" name="enddate" value={formData.enddate} onChange={handleChange} className="input-date" />
					</div>
					<label htmlFor="message">* Message:</label>
					<textarea id="message" name="message" value={formData.message} onChange={handleChange} required placeholder="Please provide the event details and desired services."></textarea>
					<i className="italic-left">* Required</i>
					<button type="submit">Send Message</button>
				</form>
				{statusMessage && <p className="status-message">{statusMessage}</p>}
				<div className="contact-social">
					<div className="width-100">
						<b>Email:</b> <a href="mailto:popdesignsbysusan@gmail.com">PopDesignsBySusan@gmail.com</a>
					</div>
				</div>
			</section>
			{showOverlay && (
				<div className="status-overlay">
					<p className="status-message">{statusMessage}</p>
				</div>
			)}
		</>
	);
}

export default Contact;
